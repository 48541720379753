type PartialSomeKeys<T, K extends keyof T> = Omit<T, K> & Partial<Pick<T, K>>;

export interface IMerchantOnboardingSliceState {
    loading: boolean;
    isInitialPageLoading: boolean;
    onboardingId: string | null;
    onboardingStatus?: OnboardingStatus;
    selectedDdStoreDetails?: PartialSomeKeys<
        StoreCandidates,
        'is_active_on_integration' | 'is_eligible_to_onboard' | 'lat' | 'lng'
    >;
    ddStoreId?: string;
    providerStoreName: string | null;
    providerAddress?: StoreAddress;
    stepsList: Steps[];
    storeList: StoreCandidates[];
    locationErrorMessage?: { statusCode?: number; message: string };
    menuStatus?: MenuStatusResponse;
    businessLocationId?: string;
    isExpressMerchant?: boolean;
    isNetNewWorkflowEnabled?: boolean;
    exchangeCode: string | null;
    isError: boolean;
    isOrderActive: boolean;
    isActivationRequested: boolean;
    exclusionCodeDetails: string;
    exclusionCode?: string;
    isBusinessHoursExist: BusinessHoursExistStatus;
    isSmartButtonModelClosed?: boolean;
    isMenusConfirmed: boolean;
}

export type Pagination = {
    pageIndex: number;
    pageSize: number;
};

export type Steps = {
    key: string;
    title: string;
    isProcessCompleted: boolean;
    buttonText?: string;
    refreshBtnText?: string;
};

export type StoreCandidates = {
    name: string;
    doordash_store_uuid: string;
    address: string;
    lat: number;
    lng: number;
    business_name: string;
    business_uuid: string;
    is_active_on_integration: boolean;
    is_eligible_to_onboard: boolean;
};

export type StoreAddress = {
    city: string;
    country: string;
    extended_address?: string;
    state: string;
    street_address: string;
    type?: string;
    zip: string;
};

export type StepsStatus = {
    is_business_hours_confirmed: boolean;
    doorDashSignInStep: boolean;
    menuStatus: boolean;
    activateStatus: boolean;
};

export enum OnboardingStatus {
    PENDING = 'PENDING',
    MENU_REQUESTED = 'MENU_REQUESTED',
    MENU_BLOCK = 'MENU_BLOCK',
    MENU_QUALIFIED = 'MENU_QUALIFIED',
    INTEGRATION_ACTIVATED = 'INTEGRATION_ACTIVATED',
    INTEGRATION_REQUESTED = 'INTEGRATION_REQUESTED',
    ACTIVATION_BLOCK = 'ACTIVATION_BLOCK',
    ABANDONED = 'ABANDONED',
}

//doordash signup API types
export type SignupUrlRequest = {
    businessLocationId: string;
};

export type SignupUrlResponse = {
    signup_url: string;
    business_hours_validate: BusinessHoursExistStatus;
};

//get store list API types

export type GetStoreListRequest = Pagination & {
    businessLocationId: string;
    exchange_code?: string;
};

export type GetStoreListResponse = {
    store_candidates: StoreCandidates[];
    offset: number;
    limit: number;
    total_count: number;
};

//store activate API types
export type ActivateIntegrationRequest = {
    onboarding_id: string;
};

export type ActivateIntegrationResponse = {
    status: string;
    message: string;
};

//Iniatialize onboarding store API types
export type InitializeOnboardingRequest = {
    doordash_store_uuid: string;
    location_id: string;
    provider_store_name: string;
    store_name: string;
    exchange_code: string | null;
    provider_store_address?: string;
    merchant_decision_maker_email?: string;
    business_name: string;
    business_uuid: string;
    dd_store_address: string;
};

export type InitializeOnboardingResponse = {
    onboarding_id: string;
};

//Get menu pull status API types
export type Menu = {
    dd_menu_id: string;
    status: string;
    merchant_supplied_id: string;
    menu_preview_link: string;
    type?: 'STOREFRONT' | 'MARKETPLACE';
    menu_error: string;
};

export enum MenuStatus {
    PENDING = 'PENDING',
    SUCCESS = 'SUCCESS',
    FAILED = 'FAILED',
}

export type MenuSuccessStatus = {
    status: MenuStatus.SUCCESS;
    messgae: string;
    menus: Menu[];
};

export type MenuPendingStatus = {
    status: MenuStatus.PENDING;
    messgae: string;
    menus: [];
};
export type MenuErrorStatus = {
    status: MenuStatus.FAILED;
    messgae: string;
    menus: [];
};
export type MenuStatusRequest = {
    onboardingId: string;
};

export type MenuStatusResponse =
    | MenuSuccessStatus
    | MenuPendingStatus
    | MenuErrorStatus;

export enum BusinessHoursExistStatus {
    UPDATED = 'UPDATED',
    NOT_UPDATED = 'NOT_UPDATED',
    UNKNOWN = 'UNKNOWN',
}

// Menus request API type
export type MenusRequest = {
    businessLocationId: string;
};

export type MenusResponse = Record<string, never>;

//Get Store integration Status API type
export type StoreIntegrationStatusRequest = {
    businessLocationId: string;
};

export type StoreIntegrationStatusResponse = StepsStatus & {
    onboarding_id: string | null;
    onboarding_status: OnboardingStatus;
    is_error: boolean;
    store_name: string;
    provider_store_name: string;
    is_order_active: boolean;
    provider_address: StoreAddress;
    exclusion_code: string;
    details: string;
    business_name: string;
    business_uuid: string;
    is_activation_requested: boolean;
    dd_store_address: string;
    dd_store_id: string;
    dd_store_uuid: string;
    is_active_on_integration?: boolean;
    is_eligible_to_onboard?: boolean;
    lat?: number;
    lng?: number;
    is_model_closed: boolean;
    business_hours_validate: BusinessHoursExistStatus;
    locationStatus: string;
    is_menus_confirmed: boolean;
};

//Store activation/deactivation API type
export type StorePauseRequest = {
    businessLocationId: string;
    is_active: boolean;
    reason?: string;
    notes?: string;
    should_send_email?: boolean;
    merchant_supplied_id?: string;
    end_time?: string;
    duration_in_hours?: number;
    duration_in_secs?: number;
};

export type StorePauseResponse = {
    success: boolean;
    message: string;
};

//SSIO restart API type
export type RestartProcessRequest = {
    businessLocationId: string;
};

export type RestartProcessResponse = {
    success: boolean;
    message: string;
};

//save selected location API type
export type SaveSelectedLocationRequest = {
    location_id: string;
    storeDetails: StoreCandidates;
};

export type SaveSelectedLocationResponse = Record<string, never>;

//remove smart button modal from UI API type
export type RemoveSmartButtonModalRequest = {
    businessLocationId: string;
    is_model_closed: boolean;
};

export type RemoveSmartButtonModalResponse = Record<string, never>;

//get business hours status API type
export type getBusinessHoursStatusRequest = {
    businessLocationId: string;
};

export type getBusinessHoursStatusResponse = {
    business_hours_validate: BusinessHoursExistStatus;
};

//business hours confirmation API type
export type BusinessHoursConfirmationRequest = {
    isBusinessHoursUpdated: boolean;
    locationId: string;
};
