import React from 'react';
import { Button, Text } from 'spoton-lib';
import { isFulfilled } from '@reduxjs/toolkit';

import { getConfigVar } from 'features/common';
import { useAppDispatch, useAppSelector } from 'api/store';
import { getSignupUrl } from '../../MerchantOnboarding.slice';
import { HTTP_STATUS_CODES } from 'utils/constants';
import Styles from './Location.module.scss';

export interface ILocationError {
    locationErrorMessage: { statusCode?: number; message: string };
}
export function LocationError({ locationErrorMessage }: ILocationError) {
    const dispatch = useAppDispatch();
    const { businessLocationId, isNetNewWorkflowEnabled } = useAppSelector(
        (state) => state.merchantOnboarding,
    );
    const onClickSignInLink = () => {
        window.location.href = getConfigVar('REACT_APP_DOORDASH_SIGNIN_URL');
    };

    const onClickSignUpLink = async () => {
        if (businessLocationId) {
            if (isNetNewWorkflowEnabled) {
                const res = await dispatch(
                    getSignupUrl({ businessLocationId }),
                );

                if (res && isFulfilled(res)) {
                    if (res.payload.status === 'success') {
                        window.location.href = res.payload.data.signup_url;
                    }
                }
            } else {
                window.location.href = getConfigVar(
                    'REACT_APP_DOORDASH_SIGNUP_URL',
                );
            }
        }
    };

    return (
        <>
            <span
                className={Styles.Location_errorMsgContainer}
                data-testid="error-message-container"
            >
                <Text color="#BA2C30" data-testid="location-error-message">
                    {locationErrorMessage.message}
                </Text>

                {locationErrorMessage.statusCode ===
                    HTTP_STATUS_CODES.PRECONDITION_FAILED ||
                locationErrorMessage.statusCode ===
                    HTTP_STATUS_CODES.NOT_FOUND ? (
                    <div className={Styles.Location_linkContainer}>
                        {locationErrorMessage.statusCode ===
                            HTTP_STATUS_CODES.NOT_FOUND && (
                            <Button
                                variant="tertiary"
                                className={Styles.Location_linkBtn}
                                onClick={onClickSignUpLink}
                                data-testid="sign-up-link"
                            >
                                <span>Create a DoorDash Store</span>
                            </Button>
                        )}
                        <Button
                            variant="tertiary"
                            className={Styles.Location_linkBtn}
                            onClick={onClickSignInLink}
                            data-testid="sign-in-link"
                        >
                            <span>Use a different DoorDash account</span>
                        </Button>
                    </div>
                ) : null}
            </span>
        </>
    );
}
