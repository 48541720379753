import React, { useEffect, useState } from 'react';
import {
    Button,
    Card,
    Col,
    Container,
    Hyperlink,
    Icon,
    LoadingOverlay,
    Row,
    Text,
} from 'spoton-lib';
import { isFulfilled } from '@reduxjs/toolkit';
import { useNavigate } from 'react-router-dom';

import styles from './LandingPage.module.scss';
import { useAppDispatch, useAppSelector } from 'api/store';
import {
    getBusinessHoursStatus,
    getSignupUrl,
    getStoreIntegrationStatus,
    restartProcess,
} from '../../MerchantOnboarding.slice';
import { PageTitle } from '../page_title';
import { ProgressIndicatorIcon } from 'features/merchant_onboarding/assets';
import {
    BusinessHoursExistStatus,
    OnboardingStatus,
} from 'features/merchant_onboarding/MerchantOnboarding.types';
import { URL } from 'utils/constants';
import { PostActivationPage } from '../post_activation_page';
import { InformationBanner } from '../information_banner';
import {
    getBusinessHoursUrl,
    getConfigVar,
    RoundedButton,
} from 'features/common';

interface ILandingPage {
    setIsBusinessHoursModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
    setIsSyncLocationModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
    setIsUserTriesToSetBusinessHours: React.Dispatch<
        React.SetStateAction<boolean>
    >;
    setMenuSelectionModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
    onTryMenuSyncAgain: () => void;
    onClickActivationBtn: () => void;
}

export function LandingPage({
    setIsBusinessHoursModalOpen,
    setIsSyncLocationModalOpen,
    setMenuSelectionModalOpen,
    setIsUserTriesToSetBusinessHours,
    onTryMenuSyncAgain,
    onClickActivationBtn,
}: ILandingPage) {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const {
        isInitialPageLoading,
        businessLocationId,
        isError,
        isActivationRequested,
        stepsList,
        onboardingStatus,
        isBusinessHoursExist,
        isExpressMerchant,
        isMenusConfirmed,
        isNetNewWorkflowEnabled,
    } = useAppSelector((state) => state.merchantOnboarding);

    const { menus } = useAppSelector((state) => state.menus);

    const [isGetStartedBtnLoading, setIsGetStartedBtnLoading] =
        useState<boolean>(false);

    const onClickSignUpLink = async () => {
        if (businessLocationId) {
            const businessHoursStepIndex = stepsList.findIndex(
                (step) => step.key === 'is_business_hours_confirmed',
            );
            const response = await dispatch(
                getBusinessHoursStatus({ businessLocationId }),
            );

            if (response && isFulfilled(response)) {
                if (response.payload.status === 'success') {
                    if (
                        response.payload.data.business_hours_validate ===
                            BusinessHoursExistStatus.UPDATED &&
                        stepsList[businessHoursStepIndex].isProcessCompleted
                    ) {
                        if (isNetNewWorkflowEnabled) {
                            const res = await dispatch(
                                getSignupUrl({ businessLocationId }),
                            );

                            if (res && isFulfilled(res)) {
                                if (res.payload.status === 'success') {
                                    window.location.href =
                                        res.payload.data.signup_url;
                                }
                            }
                        } else {
                            window.location.href = getConfigVar(
                                'REACT_APP_DOORDASH_SIGNUP_URL',
                            );
                        }
                    } else {
                        setIsBusinessHoursModalOpen(true);
                    }
                }
            }
        }
    };
    const onClickGetStartedBtn = async () => {
        if (businessLocationId) {
            setIsGetStartedBtnLoading(true);
            const response = await dispatch(
                getBusinessHoursStatus({ businessLocationId }),
            );

            setIsGetStartedBtnLoading(false);
            if (response && isFulfilled(response)) {
                if (response.payload.status === 'success') {
                    setIsBusinessHoursModalOpen(true);
                }
            }
        }
    };

    const onClickRestartBtn = async () => {
        if (businessLocationId) {
            const restartProcessRes = await dispatch(
                restartProcess({ businessLocationId }),
            );
            if (restartProcessRes && isFulfilled(restartProcessRes)) {
                if (restartProcessRes.payload.data.success) {
                    navigate(
                        {
                            search: '',
                        },
                        { replace: true },
                    );
                    await dispatch(
                        getStoreIntegrationStatus({
                            businessLocationId,
                        }),
                    );
                }
            }
        }
    };

    const onClickContactSupportLink = () => {
        window.open(URL.DOORDASH_CONTACT_SUPPORT_URL);
    };

    const onClickLocatioMenuSyncTryAgainBtn = () => {
        if (onboardingStatus === OnboardingStatus.ABANDONED) {
            onClickRestartBtn();
        } else if (onboardingStatus === OnboardingStatus.MENU_BLOCK) {
            onTryMenuSyncAgain();
        }
    };

    useEffect(() => {
        if (businessLocationId) {
            dispatch(
                getStoreIntegrationStatus({
                    businessLocationId,
                }),
            );
        }
    }, [businessLocationId]);

    if (isInitialPageLoading) {
        return <LoadingOverlay data-testid="page-loading" />;
    }

    return (
        <>
            <InformationBanner />
            <Container fluid className={styles.LandingPage}>
                <Row className={styles.LandingPage_row}>
                    {stepsList.every((step) => step.isProcessCompleted) ? (
                        <PostActivationPage
                            onClickContactSupportLink={
                                onClickContactSupportLink
                            }
                        />
                    ) : (
                        <Col
                            data-testid="landing-page-steps-container"
                            className={styles.LandingPage_stepsContainer}
                        >
                            <PageTitle />
                            {!stepsList.find(
                                (step) => step.key === 'doorDashSignInStep',
                            )?.isProcessCompleted &&
                                onboardingStatus !==
                                    OnboardingStatus.ABANDONED && (
                                    <Text
                                        className={styles.LandingPage_note}
                                        data-testid="landing-page-note"
                                    >
                                        If you&apos;re not yet a DoorDash
                                        Merchant, you can
                                        <Hyperlink
                                            onClick={onClickSignUpLink}
                                            className={
                                                styles.LandingPage_signupLink
                                            }
                                            data-testid="sign-up-link"
                                        >
                                            sign up here.
                                        </Hyperlink>
                                    </Text>
                                )}
                            <div className={styles.Steps}>
                                {stepsList.map((step, index) => (
                                    <Card
                                        key={index}
                                        className={styles.Steps_card}
                                        data-testid="landing-page-steps-card"
                                    >
                                        <Container fluid>
                                            <Row align="center">
                                                <Col
                                                    width={'2rem'}
                                                    style={{
                                                        paddingLeft: '8px',
                                                    }}
                                                >
                                                    {step.isProcessCompleted ? (
                                                        <ProgressIndicatorIcon />
                                                    ) : ((index === 0 &&
                                                          !step.isProcessCompleted) ||
                                                          (index > 0 &&
                                                              stepsList[
                                                                  index - 1
                                                              ]
                                                                  .isProcessCompleted &&
                                                              !step.isProcessCompleted)) &&
                                                      isError ? (
                                                        <Icon
                                                            name="DangerSolidIconTriangle"
                                                            size={24}
                                                        />
                                                    ) : (
                                                        <Text
                                                            className={`${
                                                                styles.Steps_cardNumber
                                                            } ${
                                                                ((index === 0 &&
                                                                    isBusinessHoursExist ===
                                                                        BusinessHoursExistStatus.UPDATED) ||
                                                                    (index >
                                                                        0 &&
                                                                        stepsList[
                                                                            index -
                                                                                1
                                                                        ]
                                                                            .isProcessCompleted)) &&
                                                                !step.isProcessCompleted &&
                                                                styles.Steps_cardNumber___completed
                                                            }`}
                                                            data-testid="landing-page-steps-card-number"
                                                        >
                                                            {index + 1}
                                                        </Text>
                                                    )}
                                                </Col>
                                                <Col>
                                                    <Text
                                                        type="h6"
                                                        data-testid="landing-page-steps-card-title"
                                                    >
                                                        {step.title}
                                                    </Text>
                                                </Col>
                                                {step.key ===
                                                    'is_business_hours_confirmed' &&
                                                    onboardingStatus ===
                                                        OnboardingStatus.ABANDONED &&
                                                    isBusinessHoursExist ===
                                                        BusinessHoursExistStatus.UNKNOWN &&
                                                    isError && (
                                                        <RoundedButton
                                                            buttonText={
                                                                step.buttonText
                                                            }
                                                            onClick={
                                                                onClickRestartBtn
                                                            }
                                                            data-testid="restart-ssio-flow-btn"
                                                        />
                                                    )}
                                                {step.key ===
                                                    'is_business_hours_confirmed' &&
                                                    isBusinessHoursExist ===
                                                        BusinessHoursExistStatus.NOT_UPDATED &&
                                                    onboardingStatus !==
                                                        OnboardingStatus.ABANDONED &&
                                                    isError && (
                                                        <RoundedButton
                                                            buttonText="Set up hours"
                                                            onClick={() => {
                                                                setIsUserTriesToSetBusinessHours(
                                                                    true,
                                                                );
                                                                window.open(
                                                                    getBusinessHoursUrl(
                                                                        Boolean(
                                                                            isExpressMerchant,
                                                                        ),
                                                                    ),
                                                                    '_blank',
                                                                );
                                                            }}
                                                        />
                                                    )}
                                                {step.key ===
                                                    'is_business_hours_confirmed' &&
                                                    isBusinessHoursExist ===
                                                        BusinessHoursExistStatus.UPDATED &&
                                                    !step.isProcessCompleted && (
                                                        <RoundedButton
                                                            buttonText="Continue"
                                                            onClick={() => {
                                                                setIsBusinessHoursModalOpen(
                                                                    true,
                                                                );
                                                            }}
                                                        />
                                                    )}
                                                {step.key ===
                                                    'doorDashSignInStep' &&
                                                    stepsList[index - 1]
                                                        .isProcessCompleted &&
                                                    !step.isProcessCompleted && (
                                                        <RoundedButton
                                                            buttonText="Continue"
                                                            onClick={() => {
                                                                window.location.href =
                                                                    getConfigVar(
                                                                        'REACT_APP_DOORDASH_SIGNIN_URL',
                                                                    );
                                                            }}
                                                        />
                                                    )}
                                                {step.key === 'menuStatus' &&
                                                    stepsList[index - 1]
                                                        .isProcessCompleted &&
                                                    !step.isProcessCompleted &&
                                                    onboardingStatus ===
                                                        OnboardingStatus.PENDING && (
                                                        <RoundedButton
                                                            buttonText="Continue"
                                                            onClick={() => {
                                                                // rajendra
                                                                // await getMenus();
                                                                if (
                                                                    !isExpressMerchant &&
                                                                    menus.length >
                                                                        1 &&
                                                                    !isMenusConfirmed
                                                                ) {
                                                                    setMenuSelectionModalOpen(
                                                                        true,
                                                                    );
                                                                } else {
                                                                    setIsSyncLocationModalOpen(
                                                                        true,
                                                                    );
                                                                }
                                                            }}
                                                        />
                                                    )}
                                                {step.key ===
                                                    'activateStatus' &&
                                                    stepsList[index - 1]
                                                        .isProcessCompleted &&
                                                    !step.isProcessCompleted && (
                                                        <RoundedButton
                                                            data-testid="activate-btn"
                                                            isLoading={
                                                                isActivationRequested
                                                            }
                                                            onClick={
                                                                onClickActivationBtn
                                                            }
                                                            buttonText={
                                                                step.buttonText
                                                            }
                                                        />
                                                    )}
                                                {step.key === 'menuStatus' &&
                                                    stepsList[index - 1]
                                                        .isProcessCompleted &&
                                                    !step.isProcessCompleted &&
                                                    isError && (
                                                        <RoundedButton
                                                            buttonText={
                                                                step.buttonText
                                                            }
                                                            data-testid="menu-request-again-btn"
                                                            onClick={
                                                                onClickLocatioMenuSyncTryAgainBtn
                                                            }
                                                        />
                                                    )}
                                            </Row>
                                        </Container>
                                    </Card>
                                ))}
                            </div>
                            {!stepsList.some(
                                (step) => step.isProcessCompleted,
                            ) &&
                                isBusinessHoursExist ===
                                    BusinessHoursExistStatus.UNKNOWN &&
                                !isError && (
                                    <Button
                                        className={
                                            styles.LandingPage_getStartedButton
                                        }
                                        data-testid="get-started-btn"
                                        onClick={onClickGetStartedBtn}
                                        isLoading={isGetStartedBtnLoading}
                                    >
                                        Get started
                                    </Button>
                                )}
                        </Col>
                    )}
                </Row>
            </Container>
        </>
    );
}
